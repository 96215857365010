import React from "react";
import * as classes from "../../styles/HowItWorkSection.module.scss";
import InfoCard from "./InfoCard";
import imgsrc from "../../images/whywe.svg";
import section2img from "../../images/hiw_section2.svg";
export default function HowItWorkSection() {

  return (
    <>
      <InfoCard
        title="Why Join Experts Circle?"
        infoText={
          <>
            <strong>Expert Endorsement:</strong> Your application will be supported by industry experts, ensuring you're not just another CV in the pile.
            <br />
            <strong>Insider Insight:</strong> Gain access to detailed insights about roles and organisations, helping you make informed decisions.
            <br />
            <strong>Increased Visibility:</strong> Let industry leaders see your potential, mediated by trusted experts who can vouch for your capabilities.
            <br />
            <strong>Increased Visibility:</strong> Experience seamless
            recruitment processes on our state-of-the-art cloud platform.
            <br />
          </>
        }
        img={section2img}
      />

      <InfoCard
        title="How it works"
        description="Dive into our streamlined process where experts, like you, can transform professional connections into successful recommendations. Here's a glimpse of how it unfolds:"
        img={imgsrc}
        infoTitle="Candidate"
        infoText={
          <>
            At Experts Circle, we connect you with market leaders through subject matter experts who advocate for your skills. Register today to boost your job applications with strong endorsements.
            <ul className={classes.stepList}>
              <li>
                <strong>Register and Showcase Your Skills:</strong> Join our platform and create a profile that highlights your expertise.
              </li>
              <li>
                <strong>Get Vetted by Experts:</strong> Our subject matter experts will review your skills and experience to ensure a good fit for potential roles.
              </li>
              <li>
                <strong>Receive Endorsed Job Matches:</strong> Once vetted, receive job recommendations where you're not just a fit—you're the perfect candidate.
              </li>
              <li>
                <strong>Interview with Confidence:</strong> Go into interviews knowing that you've been chosen and endorsed by someone who understands the job's demands and believes in your ability to meet them.
              </li>
            </ul>
            Dive deeper into our revolutionary approach with our{" "}
            <a href="/how_it_works">detailed how it works page</a>.
          </>
        }
      />
    </>
  );
}
