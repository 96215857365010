// import * as React from "react";
import React, { useRef, useState, useEffect } from 'react';
import Button from "@mui/material/Button";
import { navigate } from "gatsby";
import Lottie from "lottie-react";

import personalized from "../../lotti_json/personalized.json"
import badge from "../../lotti_json/badge.json"
import glassaccess from "../../lotti_json/glassaccess.json"
import transperancy from "../../lotti_json/transperancy.json"
import * as classes from "../../styles/employer_home.module.scss";
import HowItWorkSection from "./HowItWorkSection";
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo";
import TopRatedExperts from "./TopRatedExperts";
import { getDomainURL, windowlocation, candidateUserType} from '../../constants';

import { graphql } from 'gatsby';


const featureData = [
  {
    ani: badge,
    title: "Expert Endorsements",
    description:
      "Gain a competitive edge with the backing of an industry expert",  
  },
  {
    ani: glassaccess,
    title: "Insider Access",
    description:
      "Access high-quality, vetted job openings in leading companies",
  },
  {
    ani: personalized,

    title: "Personalised Job Matches",
    description:
      "Connect with roles that value your unique skills and experience",
  },
  {
    ani: transperancy,
    title: "Transparent Process",
    description:
      "Understand exactly where you fit with detailed insights from insiders",
  },
];

const CandidateHome = ({ data }) => {
  const location = windowlocation;
  getDomainURL(location);

  const [signUp, setSignUp] = useState(true);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const searchParams = new URLSearchParams(windowlocation.search);
      const signUpParam = searchParams.get('signup');
      setSignUp(signUpParam !== 'false');
    }
  }, []);

  const path = signUp ? '&signUp=true' : '&signUp=false';
  const buttonLabel = signUp ? 'Register' : 'Login';

  const handleButtonClick = () => {
    if (typeof window !== 'undefined') {
      const baseUrl = getDomainURL(windowlocation, candidateUserType);
      window.location.href = baseUrl + path;
    }
  };

  const [activeTab, setActiveTab] = useState(0);
  const faqRef = useRef();
  const activeSectionData = data.allPrismicCandidatelandi.edges?.[activeTab]?.node.data;

  return (
    <Layout mode="expert_home">
      <SEO title="Home" />
      <div>
        <div className={classes.section1}>
          <div className={classes.heroSection}>
            <div className={classes.textContent}>
              

              {activeSectionData && (
        <div ref={faqRef} className={classes.section} key={activeSectionData.section_title.text}>
          <h1 className={classes.mainText}>
                 {activeSectionData.section_title.text}
              </h1>
              <p className={classes.moreInfo}>
                {activeSectionData.section_details.text}
              </p>
        </div>
      )}
             
              <div className={classes.m_yVideo}>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/MCdfF-fiRG4?rel=0&controls=1"
                  title="Introducing Experts Circle"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </div>
              <div className={classes.actions}>
                <Button
                  onClick={handleButtonClick}
                  className={classes.signInWithLinkedInBut}
                  variant="contained"
                  color="secondary"
                >
                {buttonLabel}
                </Button>
                <Button
                  onClick={() => navigate("/how_it_works")}
                  className={classes.learnMoreBut}
                  variant="outlined"
                  color="secondary"
                >
                  Learn More
                </Button>
              </div>
            </div>
            <div className={classes.yVideo}>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/MCdfF-fiRG4?rel=0&controls=0"
                title="Introducing Experts Circle"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={classes.features}>
            {featureData.map(({ ani, title, description }) => {
              return (
                <div className={classes.featureCard} key={title}>
                  <div>
                    <Lottie
                      background="transparent"
                      speed="1"
                      style={{ width: 130, height: 130 }}
                      loop
                      autoPlay
                      animationData={ani}
                    />
                  </div>
                  <div>
                    <h3>{title}</h3>
                    <p>{description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <HowItWorkSection />

      <TopRatedExperts />
      </div>
    </Layout>
    
  );
};

export const query = graphql`
{
  allPrismicCandidatelandi {
    edges {
      node {
        uid
        data {
          section_title {
            text
          }
          section_details {
            text
          }
        }
      }
    }
  }
}
`;

export default CandidateHome;

